import {ecomAppDefID, EDITOR_SCRIPT_DSN, Events, SPECS} from './constants';
import {createStoreFrontBILogger} from '@wix/wixstores-client-core/dist/src/bi/configure-front-bi-logger';
import {withMembersArea} from '@wix/members-area-integration-kit';
import {Logger} from '@wix/bi-logger-ec-sf';
import {getAppManifest} from './editor-script-partials/appManifest';
import {PageMap} from '@wix/wixstores-client-core/dist/es/src/constants';
import {DependantApps} from './services/DependantApps';
import {StyleParams} from './services/StyleParams';
import {translateFunctionFactory} from '../common/translations/translations';
import {
  setSentryInstance,
  withErrorReportingWrapping,
  wrapAsyncFunctionsWithPromise,
} from '@wix/wixstores-client-storefront-sdk/dist/es/src/viewer-script/errorReporter';
import {addMembersArea, addPage, addWidget, setStateForPages} from './editor-script-partials/publicApi';
import {handleOnEvent} from './editor-script-partials/onEvent';
import {doTransaction} from './transaction';
import {handleAction} from './editor-script-partials/handleAction';
import {createAppApi} from './editor-script-partials/appApi';
import {ExperimentsApi} from '../common/experiments/ExperimentsApi';

let appToken;
let options: IEditorOptions;
let t;
let locale: string = 'en';
let biLogger: Logger;
let sdk: IEditorSdk;
let experimentsApi: ExperimentsApi;
let dependantApps: DependantApps;
let styleParams: StyleParams;
let storeId: string;

function createSentryInstance(monitoring) {
  const configuration = {
    dataCallback: (data) => {
      data.environment = 'Worker';
      return data;
    },
  };
  return monitoring.createSentryMonitorForApp(EDITOR_SCRIPT_DSN, configuration);
}

function isMembersPage(tpaPageId) {
  return tpaPageId === PageMap.ORDER_HISTORY || tpaPageId === PageMap.WISHLIST;
}

async function addStoresPagesAsPanel() {
  const tpaApplicationId = (await sdk.tpa.app.getDataByAppDefId('', ecomAppDefID)).applicationId;
  const allSitePages = await sdk.pages.data.getAll();
  const storesPages = allSitePages.filter((page) => page.tpaApplicationId === tpaApplicationId);
  return Promise.all(
    storesPages.map(async (page) => {
      const pageRef = {id: page.id, type: page.type};
      const pageData = await sdk.pages.data.get('', {
        pageRef,
      });
      if (!pageData.managingAppDefId && !isMembersPage(pageData.tpaPageId)) {
        await sdk.pages.data.update('', {
          pageRef,
          data: {managingAppDefId: ecomAppDefID},
        });
        return pageData;
      }
    })
  );
}

async function setStoresPages() {
  const shouldUseLightboxes = experimentsApi.enabled(SPECS.UseLightboxes);
  await setStateForPages(sdk);
  await dependantApps.addCheckoutIfNeeded();
  shouldUseLightboxes && (await dependantApps.addLighboxes(options.initialAppData.applicationId));
}

function registerEvents() {
  sdk.document.application.registerToCustomEvents('', {eventTypes: [Events.dashboardClosed]});
}

async function editorReady(_editorSDK, _appToken, _options) {
  options = _options;
  appToken = _appToken;
  sdk = _editorSDK;

  const instance: string = await (sdk as any).document.info.getAppInstance('');
  experimentsApi = new ExperimentsApi(instance);
  await experimentsApi.fetch();

  setSentryInstance(createSentryInstance(options.monitoring));
  wrapAsyncFunctionsWithPromise(true);
  registerEvents();

  const encodedInstance = instance.substring(instance.indexOf('.') + 1);
  const parsedInstance = JSON.parse(atob(encodedInstance));
  storeId = parsedInstance.instanceId;
  const isMerchant = true;
  biLogger = createStoreFrontBILogger({uuid: parsedInstance.uid}, parsedInstance.biToken, {
    storeId,
    isMerchant,
    appName: 'wixstores worker',
  });

  dependantApps = new DependantApps(sdk, biLogger, instance, options.initialAppData.metaSiteId);
  styleParams = new StyleParams(sdk, options.initialAppData.applicationId);
  locale = await sdk.editor.environment.getLocale();
  t = await translateFunctionFactory(locale);
  await doTransaction(
    sdk,
    async () => {
      if (!experimentsApi.enabled(SPECS.EcomPlatformInstallation)) {
        await addMembersArea(options.origin.type, options.firstInstall, options.biData);
      }
      await dependantApps.installMySubscriptionsPageInMembersAreaIfNeeded({biData: options.biData});

      const storesPages = await addStoresPagesAsPanel();
      if (storesPages.length) {
        await setStoresPages();
      }

      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      biLogger.exposureEventForTests({
        testName: SPECS.InstallNewStoresUponStoresInstallation,
        type: options.origin.type,
      });
      if (
        !experimentsApi.enabled(SPECS.EcomPlatformInstallation) &&
        experimentsApi.enabled(SPECS.InstallNewStoresUponStoresInstallation)
      ) {
        await dependantApps.installNewStoresAppIfNeeded();
      }
    },
    experimentsApi.experiments
  );
  sdk.editor.setAppAPI(
    '',
    withErrorReportingWrapping(createAppApi(sdk, dependantApps, styleParams, experimentsApi.experiments, t, biLogger))
  );
}

const publicApi = {
  addPage: (pageId: string, shouldNavigate = false) => addPage(sdk, pageId, shouldNavigate),
  addWidget: (widgetId: string, addToAllPages: boolean) => addWidget(sdk, widgetId, addToAllPages),
  addMembersArea: (editorType: string, firstInstall: boolean, biData?: {origin?: string}) =>
    addMembersArea(editorType, firstInstall, biData),
  setStateForPages: () => setStateForPages(sdk),
};

export const editorScript = withMembersArea(
  withErrorReportingWrapping({
    editorReady,
    handleAction: (data) => handleAction(data, sdk, storeId, dependantApps, biLogger),
    getAppManifest: () => getAppManifest(t, locale, appToken, experimentsApi.hasStoresPremium),
    onEvent: (data) => handleOnEvent(data, sdk, dependantApps, experimentsApi.experiments),
    getControllerPresets: () => Promise.resolve([]),
    exports: publicApi,
  }),
  {installAutomatically: false}
);
